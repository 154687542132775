import { useContext, useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import AddEditStockistModal from "./AddEditStockistModal";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import { NullHandler } from "../../../common/NullHandler";
import { Link } from "react-router-dom";
import { Badge } from "primereact/badge";
import { useForm } from "react-hook-form";
import ReactSelect from "../../../common/ReactSelect";
import { Bar } from "react-chartjs-2";
import Select from "react-select";
import XLSX from 'sheetjs-style';
import FileSaver from 'file-saver'
import { LoaderContext } from "../../../App";

export default function Stockiest() {
  const fullScreenLoader = useContext(LoaderContext);
  const [loader, setLoader] = useState(false);

  const controller = new AbortController();
  const controller2 = new AbortController();
  const apiname = process.env.REACT_APP_STOCKIST_API?.toString();
  const [isSelf, setIsSelf]: any = useState(false);
  const user = localStorage.getItem("Roles");
  const [selectedEmployee, setSelectedEmployee]: any = useState(null)
  const [hqIds, setHqIds]: any = useState(null)
  const [resetPage, setResetPage] = useState(false);
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  const [selectedRow, setSelectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => {
    return e.StockistId;
  });
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };
  const [updateId, setupdateId]: any = useState(null);

  const {
    formState: { errors },
    control,
    setValue,
  }: any = useForm({ mode: "all" });

  let division: any = localStorage.getItem("Division");
  division = JSON.parse(division || {});

  let [startDate, setStartDate] = useState("");
  let [endDate, setEndDate] = useState("");
  let [divId, setDivId]: any = useState(null);
  const [selected, setSelected]: any = useState(null);
  let [Top10StockistSale, setTop10StockistSale]: any = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - 1;
    const endingYear = currentYear;
    console.log(startingYear, endingYear, "year");
    setDivId({ value: 0, label: "All" });
    setSelected({ value: `${startingYear}-${endingYear}`, label: `${startingYear}-${endingYear}` });
    return () => {
      controller.abort();
    }
  }, []);



  let GetTop10DoctorSale = () => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL
        }Reporting/Dashboard/GetTop10StockistSale?StartDate=${startDate}&EndDate=${endDate}${divId?.value == 0 ? "" : `&DivisionId=${divId?.value}`
        }`, { signal: controller2.signal }
      )
      .then((res) => {
        setTop10StockistSale(res?.data?.Data || []);
      })
      .catch((err) => {
        console.log(err);
        setTop10StockistSale([]);
      });
  };

  useEffect(() => {
    if (selected == null) {
      return
    }
    let temp = selected?.value;
    let startdate = `${temp?.split("-")[0]}-04-01`;
    let enddate = `${temp?.split("-")[1]}-03-31`;
    setStartDate(startdate);
    setEndDate(enddate);
  }, [selected])

  function generateFinancialYears() {
    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - 10;
    const endingYear = currentYear;

    const years = [];
    for (let i = startingYear; i <= endingYear; i++) {
      years.push({
        label: `${i}-${i + 1}`,
        value: `${i}-${i + 1}`,
      });
    }
    return years;
  }


  useEffect(() => {
    if (startDate !== "" && endDate !== "" && divId !== null) {
      GetTop10DoctorSale();
    }
    return () => {
      controller2.abort();
    }
  }, [startDate, endDate, divId]);

  const options: any = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            weight: 500
          }
        },
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        ticks: {
        },
        grid: {
          drawBorder: false
        }
      }
    }
  };

  const data = {
    labels: Top10StockistSale?.map((e: any) => {
      return e?.stockistName;
    }),
    datasets: [
      {
        label: "Sales",
        data: Top10StockistSale?.map((e: any) => {
          return e?.Qty;
        }),
        backgroundColor: "#6E7EA2",
        barThickness: 60,
      },
    ],
  };

  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "3rem",
    },
    {
      header: "Action",
      sortable: false,
      body: (e: any) => actionStockiestTemplate(e),
      width: "5%",
    },
    {
      field: "StockistName",
      header: "Stockist Name",
      bodyClassName: "text-info h6",
      body: (rowData: any) => {
        return (
          <div className="align-items-center h6">
            <Link to={""} className="text-bold">
              {rowData.StockistName.toUpperCase()}
            </Link>
            <div>
              <Badge
                value={rowData.StockistTypeName}
                size="normal"
                severity={"success"}
              />
            </div>
          </div>
        );
      },
      sortable: true,
      width: "20%",
      mergeColums: true,
      mergeColumnsName: ["StockistName", "StockistTypeName"],
    },
    {
      field: "CfaLocation",
      header: "CFA Location",
      body: (data: any) => {
        return NullHandler(data.CfaLocation);
      },
      sortable: true,
      width: "15%",
    },
    {
      field: "Address",
      header: "Address",
      body: (data: any) => {
        return (
          <p
            title={data.Address}
            style={{
              width: "200px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              padding: 0,
              margin: 0,
            }}
          >
            {data.Address === "" ? " - " : data.Address}
          </p>
        );
      },
      sortable: true,
      width: "20%",
    },
    {
      field: "MobileNo1",
      header: "Mobile No1 | Mobile No2 | Phone No | Email",
      body: (data: any) => {
        return `${NullHandler(data.MobileNo1)} | ${NullHandler(
          data.MobileNo2
        )} | ${NullHandler(data.PhoneNo)} | ${NullHandler(data.Email)}`;
      },
      width: "40%",
      mergeColums: true,
      mergeColumnsName: ["MobileNo1", "MobileNo2", "PhoneNo", "Email"],
    },
  ];

  const handle = async (params: any, pra: any) => {
    const data = {
      StockistId: params,
      Status: pra,
    };
    await axiosInstance
      .put(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_STOCKISTAPPROVAL_API}/${params}`,
        data
      )
      .then(() => {
        toast.success(
          pra === "Approve" ? `Approved Successfull` : `Rejected Successfull`,
          { position: "top-right" }
        );
        setbool(true);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  const actionStockiestTemplate = (id: any) => {
    return id.StatusName === "Pending" ? (
      <>
        {user === "BDM" ||
          user === "ASM" ||
          user === "RSM" ||
          user === "ZSM" ||
          user === "admin" ? (
          <div className="d-flex flex-column">
            <button
              className="btn btn-success p-2 mb-1 "
              onClick={() => handle(id.StockistId, "Approve")}
            >
              Approve
            </button>
            <button
              className="btn btn-danger p-2"
              onClick={() => handle(id.StockistId, "Reject")}
            >
              Reject
            </button>
          </div>
        ) : (
          <span
            className={`badge badge-${id.StatusName == "Pending" ? "primary" : ""
              }`}
          >
            {" "}
            {id.StatusName}{" "}
          </span>
        )}
      </>
    ) : (
      <Action
        id={id.StockistId}
        editModal={() => {
          setupdateId(id.StockistId);
        }}
        displayid={setDeleteidtoDelete}
      />
    );
  };

  const actionDateTemplate = (id: any) => {
    return id.CreationDate?.split("T")[0];
  };

  // Export Excel
  const [approvalData, setApprovalData]: any = useState(null);
  const filter = {
    filters: [{ field: 'StatusName', operator: 'eq', value: 'Pending' }],
    logic: 'and',
  };
  useEffect(() => {
    axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_STOCKIST_API}/Get?PageNumber=1&PageSize=100&Filter=${JSON.stringify(filter)}`, { signal: controller.signal }).then((res: any) => {

      const newData = res.data.Data.map((mapData: any) => {
        let TempObj: any = {};
        const RemoveField = ["AddressId", "CityId", "CreatedBy", "EmployeeId", "PincodeId", "StatusId", "StockistId", "StockistTypeId"]
        const keys = Object.keys(mapData);
        keys.map((keyMap: any) => {
          if (!(RemoveField.includes(keyMap))) {
            if (Array.isArray(mapData[keyMap])) {

              const meargdata = mapData[keyMap].map((innerMap: any) => {
                return innerMap[`${keyMap.split("List")[0]}Name`]
              })
              TempObj = { ...TempObj, [keyMap]: meargdata.join(",") }

            }
            else {

              TempObj = { ...TempObj, [keyMap]: mapData[keyMap] }
            }
          }
        })
        return TempObj;
      })

      setApprovalData(newData)
    }).catch((err: any) => {
      if (controller.signal.reason.code == 20) {
        console.log('Request Canceled', err)
      }
    })
  }, [])
  const downloadPendingApprovals = async () => {
    const fileType = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    const fileExtension = '.xlsx'

    const ws = XLSX.utils.json_to_sheet(approvalData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Stockist Pending Approvals" + fileExtension)
  }

  
  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };

  const GetExportExcelFile = async () => {
    setLoader(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_STOCKISTEXPORTDOCTORDETAILS_API}?ExportType=2`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(response.data, "StockistData.xlsx");
        toast.success(`Download Successfull`, { position: "top-right" });
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
      });
  };

  useEffect(() => {
    if (loader) {
      fullScreenLoader.setloader(true);
    } else {
      fullScreenLoader.setloader(false);
    }
  }, [loader]);


  return (
    <>
      <Helmet>
        <title>Stockist</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Stockist"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          downloadlist
          onClickDownload={downloadPendingApprovals}
          linkflag
          linkflagURL={"/layout/adminpanel"}
          linkflagtitle={"Admin Panel"}
        />

        {user === "admin" && (
          <>
            <div className="row">
              <ReactSelect
                label="Division"
                containerClass="col-lg-6"
                name={"DivisionId"}
                onChange={(e: any) => {
                  setDivId(e);
                }}
                value={divId}
                control={control}
                api={process.env.REACT_APP_DIVISIONGETDASHBOARDLOOKUP_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.DivisionId?.message}
              />
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="col-form-label">
                    Financial Year <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={generateFinancialYears()}
                    className="z-3"
                    isClearable
                    classNamePrefix={"z-3"}
                    onChange={(e: any) => {
                      setSelected(e);
                    }}
                    value={selected}
                  />
                </div>
              </div>
            </div>

            {/* <div className="row mt-2">
              <div className="col-lg-12 mb-20">
                <div className="pd-20 card-box mb-30">
                  <Bar height={60} options={options} data={data} />
                </div>
              </div>
            </div> */}
            <div className="row mt-2">
              <div className="col mb-20">
                <div className="pd-20 card-box mb-30" style={{ position: 'relative', height: '50vh', display: 'flex', justifyContent: 'center' }}>
                  <Bar options={options} data={data} />
                </div>
              </div>
            </div>

          </>
        )}
        {
          user !== "MR" &&
          <div className="row">
            <ReactSelect
              containerClass="col-lg-4"
              label="Employee"
              name={"Id"}
              onChange={(e: any) => {

                if (e === null) {
                  setSelectedEmployee(null);
                  // setbool(true);
                  setResetPage(true)
                }
                else {
                  setIsSelf(false);
                  setSelectedEmployee(e);
                  setResetPage(true)

                }
                // setValue("PermissionId", e?.value);
              }}
              value={selectedEmployee}
              control={control}
              api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
              keys={{ label: "Value", value: "Id" }}

            />
            <ReactSelect
              containerClass="col-lg-4"
              label="HQ"
              name={"hqIds"}
              onChange={(e: any) => {
                setHqIds(e);
                setValue("hqIds", e?.value);
                setResetPage(true)
              }}
              value={hqIds}
              api={process.env.REACT_APP_HQ_API}
              // api={process.env.REACT_APP_HQ_BY_STATE_API}
              // Disabled={stateIds !== null ? false : true}
              keys={{ label: "Value", value: "Id" }}
              control={control}
            // flag
            />
            <div className="col-md-4">
              <div className="custom-control custom-checkbox pt-5">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  onChange={(e) => {
                    if (e.target.checked == true) {
                      setSelectedEmployee(null);
                    }
                    setIsSelf(e.target.checked);
                    // setbool(true)
                    setResetPage(true)
                  }}
                  id="New"
                  checked={isSelf}
                />
                <label className="custom-control-label" htmlFor="New">
                  Self
                </label>
              </div>
            </div>
          </div>
        }
        <DatatableCommon
          apiname={apiname}
          multiDelete={(data: boolean) => {
            if (data) {
              setdeleteID(selectedRow?.length === 0 ? null : multi);
            }
          }}
          setselecteditem={setSelectedRow}
          selecteditem={selectedRow}
          columnData={columns}
          // check={true}
          refetchData={bool}
          setrefetchData={setbool}
          employeeId={selectedEmployee?.value}
          resetPage={resetPage}
          cancelReset={() => setResetPage(false)}
          // flagIsSelf={true}
          flagIsSelf={isSelf}
          hqId={hqIds?.value}
          exportExcelApi={GetExportExcelFile}
          exportExcelFlag
        />

        {updateId !== null && (
          <AddEditStockistModal
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div >

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
}
