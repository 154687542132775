import { useContext, useEffect, useState } from "react";
import { Action } from "../../common/Action";
import { DeleteModal } from "../../common/DeleteModal";
import { Helmet } from "react-helmet";
import DatatableCommon from "../../common/DatatableCommon";
import axiosInstance from "../../_helper/Interceptor";
import { toast } from "react-toastify";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { AddEditDoctor } from "./AddEditDoctor";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "primereact/badge";
import moment from "moment";
import { NullHandler } from "../../common/NullHandler";
import { Button } from "../../common/Button";
import ReactSelect from "../../common/ReactSelect";
import { useForm } from "react-hook-form";
import { Bar } from "react-chartjs-2";
import Select from "react-select";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
import { SpeedDial } from "primereact/speeddial";
import { LoaderContext } from "../../App";

export const Doctor = () => {
  const controller = new AbortController();
  const controller2 = new AbortController();

  const fullScreenLoader = useContext(LoaderContext);
  const [loader, setLoader] = useState(false);

  let navigate = useNavigate();
  const apiname = process.env.REACT_APP_DOCTORDETAIL_API?.toString();

  const user = localStorage.getItem("Roles");

  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);
  const [approvalData, setApprovalData]: any = useState([]);
  const [selectedEmployee, setSelectedEmployee]: any = useState(null);
  const [hqIds, setHqIds]: any = useState(null);
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const {
    formState: { errors },
    control,
    setValue,
  }: any = useForm({ mode: "all" });

  let division: any = localStorage.getItem("Division");
  division = JSON.parse(division || {});

  let [startDate, setStartDate] = useState("");
  let [endDate, setEndDate] = useState("");
  let [divId, setDivId]: any = useState(null);
  const [resetPage, setResetPage] = useState(false);
  const [selected, setSelected]: any = useState(null);
  const [isSelf, setIsSelf]: any = useState(false);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - 1;
    const endingYear = currentYear;
    setDivId({ value: 0, label: "All" });
    setSelected({
      value: `${startingYear}-${endingYear}`,
      label: `${startingYear}-${endingYear}`,
    });

    return () => {
      controller.abort();
    };
  }, []);

  let [Top10DoctorSale, setTop10DoctorSale]: any = useState([]);
  let GetTop10DoctorSale = () => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${
          process.env.REACT_APP_DASHBOARDGETTOPTENDOCTORSALE_API
        }?StartDate=${startDate}&EndDate=${endDate}${
          divId?.value == 0 ? "" : `&DivisionId=${divId?.value}`
        }`,
        { signal: controller2.signal }
      )
      .then((res) => {
        setTop10DoctorSale(res?.data?.Data || []);
      })
      .catch((err) => {
        if (controller2.signal.reason.code == 20) {
          console.log("Request Canceled", err);
        }
        setTop10DoctorSale([]);
      });
  };

  useEffect(() => {
    if (selected == null) {
      return;
    }
    let temp = selected?.value;
    let startdate = `${temp?.split("-")[0]}-04-01`;
    let enddate = `${temp?.split("-")[1]}-03-31`;
    setStartDate(startdate);
    setEndDate(enddate);
  }, [selected]);

  function generateFinancialYears() {
    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - 10;
    const endingYear = currentYear;

    const years = [];
    for (let i = startingYear; i <= endingYear; i++) {
      years.push({
        label: `${i}-${i + 1}`,
        value: `${i}-${i + 1}`,
      });
    }
    return years;
  }
  const filter = {
    filters: [{ field: "StatusName", operator: "eq", value: "Pending" }],
    logic: "and",
  };
  useEffect(() => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${
          process.env.REACT_APP_DOCTORDETAIL_API
        }/Get?PageNumber=1&PageSize=100&Filter=${JSON.stringify(filter)}`,
        { signal: controller.signal }
      )
      .then((res: any) => {
        const newData = res.data.Data.map((mapData: any) => {
          let TempObj: any = {};
          const RemoveField = [
            "CategoryId",
            "CreatedBy",
            "DateOfAnniversary",
            "DoctorId",
            "EmployeeId",
            "HospitalAddressId",
            "HospitalCityId",
            "HospitalPincodeId",
            "HospitalRoleTypeId",
            "ResidencialAddressId",
            "ResidencialCityId",
            "ResidencialPincodeId",
            "StatusId",
            "UserId",
            "RegoinId",
          ];
          const keys = Object.keys(mapData);
          keys.map((keyMap: any) => {
            if (!RemoveField.includes(keyMap)) {
              if (Array.isArray(mapData[keyMap])) {
                const meargdata = mapData[keyMap].map((innerMap: any) => {
                  return innerMap[`${keyMap.split("List")[0]}Name`];
                });
                TempObj = { ...TempObj, [keyMap]: meargdata.join(",") };
              } else {
                TempObj = { ...TempObj, [keyMap]: mapData[keyMap] };
              }
            }
          });
          return TempObj;
        });

        setApprovalData(newData);
      })
      .catch((error) => {
        if (controller.signal.reason.code == 20) {
          console.log("Request Canceled", error);
        } else {
          setApprovalData([]);
        }
      });
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && divId !== null) {
      GetTop10DoctorSale();
      return () => {
        controller2.abort();
      };
    }
  }, [startDate, endDate, divId]);
  const role = localStorage.getItem("Roles");
  const options: any = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            weight: 500,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {},
        grid: {
          drawBorder: false,
        },
      },
    },
  };

  const data = {
    labels: Top10DoctorSale?.map((e: any) => {
      return e?.doctorName;
    }),
    datasets: [
      {
        label: "Sales",
        data: Top10DoctorSale?.map((e: any) => {
          return e?.Amount;
        }),
        backgroundColor: "#6E7EA2",
        barThickness: 40,
      },
    ],
  };

  const filterJson = {
    DoctorName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    CategoryName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Email: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Mobile: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Phone: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    WebSite: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    PanCardNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    RegistrationNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    SoftwareName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    HospitalRoleName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    // DateOfBirth: {
    //   operator: FilterOperator.AND,
    //   constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    // },
  };

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "2%",
    },
    {
      field: "action",
      header: "Action",
      // frozen: true,
      body: (e: any) => actionChemistCategoryTemplate(e),
      sortable: false,
      width: "7%",
    },
    {
      field: "DoctorName",
      header: "Doctor Name | Speciality",
      bodyClassName: " h6 text-bold",
      filter: true,
      body: (e: any) => DoctorNameAndCategoryTemplate(e),
      filterPlaceholder: "Search by doctor name",
      sortable: true,
      width: "23%",
      mergeColums: true,
      mergeColumnsName: ["DoctorName", "SpecialityList.SpecialityName"],
    },
    {
      field: "CategoryName",
      header: "Category Name",
      bodyClassName: " h6 text-bold",
      filter: true,
      body: (e: any) => CategoryTemplate(e),
      filterPlaceholder: "Search by category name",
      sortable: true,
      width: "10%",
    },
    {
      field: "Mobile",
      header: "Mobile | Phone | Email",
      body: (data: any) => {
        return `${NullHandler(data.Mobile)} | ${NullHandler(
          data.Phone
        )} | ${NullHandler(data.Email)}`;
      },
      sortable: true,
      width: "23%",
      mergeColums: true,
      mergeColumnsName: ["Mobile", "Phone", "Email"],
    },

    {
      field: "HospitalAddress",
      header: "Hospital Address",
      body: (data: any) => {
        return (
          <p
            title={data.HospitalAddress}
            style={{
              width: "200px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              wordBreak: "break-all",
              margin: 0,
              padding: 0,
            }}
          >
            {data.HospitalAddress === "" ? " - " : data.HospitalAddress}
          </p>
        );
      },
      sortable: true,
      width: "15%",
    },
    {
      field: "DateOfBirth",
      filterField: "DateOfBirth",
      header: "DOB",
      dataType: "date",
      body: (data: any) => {
        if (data.DateOfBirth === null) {
          return "-";
        } else {
          return (
            <>
              <Badge
                className="m-1"
                severity={"success"}
                value={
                  <div
                    style={{
                      display: "inline-block",
                      maxWidth: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      verticalAlign: "middle",
                    }}
                    title={moment(data.DateOfBirth).format("DD MMM, YYYY")}
                  >
                    {moment(data.DateOfBirth).format("DD MMM, YYYY")}
                  </div>
                }
              ></Badge>
            </>
          );
        }
      },
      sortable: true,
      width: "10%",
    },
    {
      header: "Patients D | W | M",
      body: (e: any) => actionPatientRecordTemplate(e),
      width: "10%",
      mergeColums: true,
      mergeColumnsName: ["PatientsDaily", "PatientsWeekly", "PatientsMonthly"],
    },
  ];

  const [updateId, setupdateId]: any = useState(null);

  const handle = async (params: any, pra: any) => {
    const data = {
      DoctorId: params,
      Status: pra,
    };
    await axiosInstance
      .put(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DOCTORDETAILDOCTORAPPROVAL_API}/${params}`,
        data
      )
      .then(() => {
        toast.success(
          pra === "Approve" ? `Approved Successfull` : `Rejected Successfull`,
          { position: "top-right" }
        );
        setbool(true);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  const actionChemistCategoryTemplate = (id: any) => {
    return id.StatusName === "Pending" ? (
      <>
        {user === "BDM" ||
        user === "ASM" ||
        user === "RSM" ||
        user === "ZSM" ||
        user === "admin" ? (
          <div className="d-flex flex-column">
            <button
              className="btn btn-success btn-sm p-2 mb-1"
              onClick={() => handle(id.DoctorId, "Approve")}
            >
              Approve
            </button>
            <button
              className="btn btn-danger btn-sm  p-2"
              onClick={() => handle(id.DoctorId, "Reject")}
            >
              Reject
            </button>
          </div>
        ) : (
          <span
            className={`badge badge-${
              id.StatusName == "Pending" ? "primary" : ""
            }`}
          >
            {" "}
            {id.StatusName}{" "}
          </span>
        )}
      </>
    ) : (
      <Action
        id={id.DoctorId}
        editModal={() => {
          setupdateId(id.DoctorId);
        }}
        addDetail={"layout/doctorDetail"}
        displayid={setDeleteidtoDelete}
      />
    );
  };

  const actionPatientRecordTemplate = (id: any) => {
    const daily = id.PatientsDaily == null ? "" : "D-" + id.PatientsDaily;
    const week = id.PatientsWeekly == null ? "" : "| W-" + id.PatientsWeekly;
    const month = id.PatientsMonthly == null ? "" : "| M-" + id.PatientsMonthly;

    return (daily + " " + week + " " + month).trim() || " - ";
  };

  const DoctorNameAndCategoryTemplate = (rowData: any) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <div>
            <Link
              to={`/layout/doctorView/${rowData.DoctorId}`}
              className="text-bold"
            >
              {rowData.DoctorName.toUpperCase()}
            </Link>
          </div>
        </div>
        <div>
          {rowData.SpecialityList.map((e: any) => {
            return (
              <Badge
                value={e.SpecialityName}
                size="normal"
                severity={"info"}
                style={{ backgroundColor: "#978EEB" }}
              />
            );
          })}
        </div>
      </>
    );
  };

  const CategoryTemplate = (rowData: any) => {
    return (
      <>
        {rowData.CategoryName != null && (
          <Badge
            value={rowData.CategoryName}
            size="normal"
            style={{
              backgroundColor: `${
                rowData.CategoryName === "A"
                  ? "green"
                  : rowData.CategoryName === "B"
                  ? "#d4af37"
                  : rowData.CategoryName === "C"
                  ? "red"
                  : "gray"
              }`,
            }}
          />
        )}
      </>
    );
  };

  const downloadPendingApprovals = async () => {
    const fileType =
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(approvalData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Doctor Pending Approvals" + fileExtension);
  };

  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };

  const GetExportExcelFile = async () => {
    setLoader(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DOCTORDETAILEXPORTDOCTORDETAILS_API}?ExportType=2`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(response.data, "DoctorData.xlsx");
        toast.success(`Download Successfull`, { position: "top-right" });
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
      });
  };

  useEffect(() => {
    if (loader) {
      fullScreenLoader.setloader(true);
    } else {
      fullScreenLoader.setloader(false);
    }
  }, [loader]);

  return (
    <>
      <Helmet>
        <title> Doctor </title>
      </Helmet>
      <div className="min-height-200px ">
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>Doctor</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/layout/adminpanel"}>Admin Panel</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Doctor
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6">
              {role === "admin" && (
                <div className="pull-right">
                  <Button
                    text={`CRM`}
                    onclick={() => {
                      navigate("/layout/crm/crmaccount");
                    }}
                    color="secondary"
                  />
                </div>
              )}

              <div className="pull-right">
                {/* <Button
                  text="Download Excel"
                  onclick={() => {
                    GetExportExcelFile();
                  }}
                  color="secondary"
                /> */}
                <Button
                  text="Download"
                  onclick={() => {
                    downloadPendingApprovals();
                  }}
                  color="secondary"
                />
                <Button
                  text={`Add Doctor`}
                  onclick={() => {
                    setupdateId(0);
                  }}
                  color="primary"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <ReactSelect
            label="Division"
            containerClass="col-lg-6"
            name={"DivisionId"}
            onChange={(e: any) => {
              setDivId(e);
            }}
            value={divId}
            control={control}
            api={process.env.REACT_APP_DIVISIONGETDASHBOARDLOOKUP_API}
            keys={{ label: "Value", value: "Id" }}
            errorMessage={errors?.DivisionId?.message}
          />
          <div className="col-lg-6">
            <div className="form-group">
              <label className="col-form-label">
                Financial Year <span className="text-danger">*</span>
              </label>
              <Select
                options={generateFinancialYears()}
                className="z-3"
                isClearable
                classNamePrefix={"z-3"}
                onChange={(e: any) => {
                  setSelected(e);
                }}
                value={selected}
              />
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col mb-20">
            <div
              className="pd-20 card-box mb-30"
              style={{
                position: "relative",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Bar options={options} data={data} />
            </div>
          </div>
        </div>
        {user !== "MR" && (
          <>
            <div className="row">
              <ReactSelect
                containerClass="col-lg-4"
                label="Employee"
                name={"Id"}
                onChange={(e: any) => {
                  if (e === null) {
                    setSelectedEmployee(null);
                    setResetPage(true);
                  } else {
                    setIsSelf(false);
                    setSelectedEmployee(e);
                    setResetPage(true);
                  }
                }}
                value={selectedEmployee}
                control={control}
                api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
                keys={{ label: "Value", value: "Id" }}
              />
              <ReactSelect
                containerClass="col-lg-4"
                label="HQ"
                name={"hqIds"}
                onChange={(e: any) => {
                  setHqIds(e);
                  setValue("hqIds", e?.value);
                  setResetPage(true);
                }}
                value={hqIds}
                api={process.env.REACT_APP_HQ_API}
                keys={{ label: "Value", value: "Id" }}
                control={control}
              />

              <div className="col-md-4">
                <div className="custom-control custom-checkbox pt-5">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    onChange={(e) => {
                      if (e.target.checked == true) {
                        setSelectedEmployee(null);
                      }
                      setIsSelf(e.target.checked);
                      setResetPage(true);
                    }}
                    id="New"
                    checked={isSelf}
                  />
                  <label className="custom-control-label" htmlFor="New">
                    Self
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
        <DatatableCommon
          apiname={apiname}
          setselecteditem={setselectedRow}
          selecteditem={selectedRow}
          columnData={columns}
          refetchData={bool}
          showGridlines
          exportExcelApi={GetExportExcelFile}
          setrefetchData={setbool}
          filterJson={filterJson}
          employeeId={selectedEmployee?.value}
          hqId={hqIds?.value}
          exportExcelFlag
          resetPage={resetPage}
          cancelReset={() => setResetPage(false)}
          flagIsSelf={isSelf}
        />

        {updateId !== null && (
          <AddEditDoctor
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>
      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
