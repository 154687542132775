import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactSelect from "../../common/ReactSelect";
import { useForm } from "react-hook-form";
import axiosInstance from "../../_helper/Interceptor";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { Badge } from "primereact/badge";
import { Spinner } from "../../common/Spinner";

const VisitReport = () => {
  const controller = new AbortController();
  const {
    formState: { errors },
    control,
    setValue,
  }: any = useForm({ mode: "all" });
  const role = localStorage.getItem("Roles");
  const [selecteddate, setselecteddate]: any = useState(null);
  const [selecteduser, setselecteduser]: any = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubLoading, setSubIsLoading] = useState(false);
  const [isempLoading, setempIsLoading] = useState(false);
  const [visitData, setVisitData]: any = useState([]);
  const [expandedRows, setExpandedRows]: any = useState(null);
  const [subexpandedRows, setSubExpandedRows]: any = useState(null);
  const [doctorData, setDoctorData]: any = useState([]);
  const [employeeData, setEmployeeData]: any = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  function compareKeys(a: any, b: any) {
    var aKeys = Object.keys(a).sort();
    return aKeys[0] == b;
  }

  const visitReportData = () => {
    setIsLoad(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_REPORTINGVISITREPORT_API
        }?Self=${selecteduser !== null ? false : true}${selecteddate !== null ? `&dateFilterId=${selecteddate?.value}` : ""
        }${selecteduser !== null ? `&UserId=${selecteduser?.value}` : ""}`, { signal: controller.signal }
      )
      .then((e) => {
        setIsLoad(false);
        setVisitData(e.data.Data);

        console.log(e.data.Data, "data visit ==>");
      })
      .catch((err: any) => {

        console.log(err, "Error data");
        setIsLoad(false);
      });
  };

  useEffect(() => {
    visitReportData();
    return () => {
      controller.abort();
    }
  }, [selecteddate, selecteduser]);

  const doctorReportData = (id: any, doctorId: any) => {
    setSubIsLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_REPORTINGGETSPECIALITYWISEDOCTORVISITREPORT_API
        }?SpecialityIds=${id}&Self=true${selecteddate !== null ? `&dateFilterId=${selecteddate?.value}` : ""
        }&UserIds=${doctorId}`
      )
      .then((e) => {
        console.log(e, "dcr");
        setSubIsLoading(false);
        setDoctorData(e.data.Data[0]);
      })
      .catch((err: any) => {
        console.log(err, "Error data");
        setSubIsLoading(false);
      });
  };

  const employeeReportData = (id: any, doctorId: any) => {
    setempIsLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_REPORTINGGETDOCTORVISITDETAILS_API
        }?doctorId=${id}&userId=${doctorId}${selecteddate !== null ? `&dateFilterId=${selecteddate?.value}` : ""
        }`
      )
      .then((e) => {
        setempIsLoading(false);
        console.log(e.data.Data, "emp data");
        e.data.Data.map((foundUser: any) => {
          if (foundUser.UserId === doctorId) {
            setEmployeeData(foundUser);
          }
        });
      })
      .catch((err: any) => {
        console.log(err, "Error data");
        setempIsLoading(false);
      });
  };

  const tempFunction = (rowData: any) => {
    console.log(
      expandedRows !== null ? Object?.keys(expandedRows)[0] : "",
      "spe"
    );
    return (
      <Badge
        style={{ cursor: "pointer" }}
        value={rowData?.SpecialityName}
        size="normal"
        severity={`${rowData?.id ==
          (expandedRows !== null && Object?.keys(expandedRows)[0])
          ? "warning"
          : "info"
          }`}
        onClick={(e) => {
          let obj: any = {};

          if (expandedRows != null && compareKeys(expandedRows, rowData.id)) {
            obj = {};
            setDoctorData([]);
          } else {
            obj[rowData.id] = true;
            doctorReportData(rowData.SpecialityId, rowData.UserId);
          }
          setExpandedRows(obj);
          setSubExpandedRows(null);
        }}
      />
    );
  };

  const tempDoctorFunction = (rowData: any) => {
    return (
      <>
        <span className="mr-2">
          <Badge
            onClick={() => {
              let obj: any = {};
              if (
                subexpandedRows != null &&
                compareKeys(subexpandedRows, rowData.DoctorId)
              ) {
                obj = {};
                setEmployeeData([]);
              } else {
                obj[rowData.DoctorId] = true;
                employeeReportData(rowData.DoctorId, rowData.UserId);
              }
              setSubExpandedRows(obj);
            }}
            style={{ cursor: "pointer" }}
            severity={"info"}
            value={rowData.DoctorName}
          ></Badge>
        </span>
      </>
    );
  };

  const subEmployeeComponentVisit = () => {
    console.log(employeeData, "speciality for em");
    const temp = employeeData?.getAverageReportViewModels?.map(
      (d: any, i: any) => {
        return {
          ...d,
          SrNo: i + 1,
        };
      }
    );
    return (
      <div className="p-3">
        <DataTable
          value={temp}
          loading={isempLoading}
          showGridlines
          stripedRows
          rowExpansionTemplate={subComponentVisit}
        >
          <Column field="SrNo" header="#" style={{ width: '3%' }} />
          <Column field="EmployeeName" header="Employee Name" />
          <Column
            field="VisitDateTime"
            header="VisitDateTime"
            body={(e: any) => {
              return moment(e.VisitDateTime).format("DD MMM, YYYY hh:mm A");
            }}
          />
        </DataTable>
      </div>
    );
  };

  const subComponentVisit = () => {
    const temp = doctorData?.SpecialityWiseDoctorViewModels?.map(
      (d: any, i: any) => {
        return {
          ...d,
          SrNo: i + 1,
        };
      }
    );
    return (
      <div className="p-3">
        <DataTable
          value={temp}
          loading={isSubLoading}
          showGridlines
          dataKey="DoctorId"
          stripedRows
          expandedRows={subexpandedRows}
          emptyMessage="No Visit for doctors"
          onRowToggle={(e: any) => {
            setSubExpandedRows(e.data);
          }}
          rowExpansionTemplate={subEmployeeComponentVisit}
        >
          <Column field="SrNo" header="#" style={{ width: '3%' }} />
          <Column
            field="DoctorName"
            header="Doctor Name"
            body={tempDoctorFunction}
          />
          <Column field="VisitCount" header="Visit Count" />
        </DataTable>
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <title>Visit Report</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Visit Report</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/reports"}>Reports</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Visit Report
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form>
          <div className="row">
            <ReactSelect
              containerClass={role === "MR" ? "col-lg-12" : "col-lg-6"}
              label="Period"
              name={"dateFilterId"}
              onChange={(e: any) => {
                setExpandedRows(null);
                setSubExpandedRows(null);
                setselecteddate(e);
                console.log(e, "dateFilterId");
                setValue("dateFilterId", e);
              }}
              value={selecteddate === null ? null : selecteddate}
              api={process.env.REACT_APP_DATEFILTER_API}
              keys={{ label: "Value", value: "Id" }}
              control={control}
              errorMessage={errors?.dateFilterId?.message}
            />

            {role !== "MR" && (
              <ReactSelect
                containerClass="col-lg-6"
                label="User"
                name={"UserId"}
                onChange={(e: any) => {
                  setExpandedRows(null);
                  setSubExpandedRows(null);
                  setselecteduser(e);
                  setValue("UserId", e);
                }}
                value={selecteduser === null ? null : selecteduser}
                api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
                keys={{ label: "Value", value: "Id" }}
                control={control}
                errorMessage={errors?.UserId?.message}
              />
            )}
          </div>
        </form>
      </div>

      {!isLoad ? (
        <>
          <div className="pd-20 card-box mb-30">
            {visitData.map((e: any) => {
              let temp = e.DoctorVisitCountViewModels.map(
                (d: any, index: number) => {
                  return {
                    ...d,
                    id: `${e.UserName}-${index + 1}`,
                    UserId: e.UserId,
                    SrNo: index + 1,
                  };
                }
              );

              console.log(temp, "temp====>");
              return (
                <>
                  <table className="table table-striped table-bordered mt-3">
                    <tr
                      style={{
                        textAlign: "center",
                        backgroundColor: "#1977CC",
                        color: "white",
                      }}
                    >
                      <td
                        colSpan={6}
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        {e.UserName}
                      </td>
                    </tr>
                  </table>
                  <DataTable
                    dataKey="id"
                    stripedRows
                    value={temp}
                    showGridlines
                    expandedRows={expandedRows}
                    // onRowToggle={(e: any) => {
                    //   setExpandedRows(e.data);
                    // }}
                    loading={isLoading}
                    size="small"
                    rowExpansionTemplate={subComponentVisit}
                  >
                    <Column field="SrNo" header="#" style={{ width: '3%' }} />
                    <Column
                      field="SpecialityName"
                      header="Speciality Name"
                      body={tempFunction}
                    />
                    <Column
                      field="DoctorCount"
                      header="Doctor Count"
                      style={{ width: "300px" }}
                    />
                    <Column
                      field="VisitCount"
                      header="Visit Count"
                      style={{ width: "300px" }}
                    />
                  </DataTable>
                </>
              );
            })}
          </div>
        </>
      ) : (
        <div className="col-md-12 text-center">
          <Spinner width={"10rem"} height={"10rem"} />
        </div>
      )}
    </>
  );
};

export default VisitReport;
