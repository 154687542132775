import React, { useEffect, useRef, useState } from "react";
import { PaginationService } from "../service/PaginationService";
import { toast } from "react-toastify";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";
import { Paginator } from "primereact/paginator";
import { SpeedDial } from "primereact/speeddial";
import { useForm } from "react-hook-form";
import logo from "../assets/images/logo-removebg-preview.png";
import { FilterMatchMode } from "primereact/api";

interface iPropstable {
  apiname?: string;
  columnData?: any;
  refetchData?: any;
  setrefetchData?: any;
  doctorid?: number;
  selecteditem?: any;
  setselecteditem?: any;
  multiDelete?: any;
  check?: any;
  refetch?: any;
  type?: any;
  showGridlines?: any;
  filterJson?: any;
  enableDownload?: any;
  flagIsSelf?: any;
  isCalenderLeave?: any;
  pdfTitle?: any;
  employeeId?: any;
  resetPage?: any;
  cancelReset?: any;
  hqId?: any;
  exportExcelApi?: any
  exportExcelFlag?:boolean
}

const DatatableCommon = ({
  apiname,
  employeeId,
  hqId,
  columnData,
  refetchData,
  setrefetchData,
  doctorid,
  selecteditem,
  setselecteditem,
  multiDelete,
  check,
  refetch,
  type,
  showGridlines = true,
  filterJson,
  enableDownload = true,
  flagIsSelf = false,
  exportExcelApi,
  exportExcelFlag = false,
  isCalenderLeave = "false",
  pdfTitle = window.location.pathname
    .split("/")
  [window.location.pathname.split("/").length - 1].charAt(0)
    .toUpperCase() +
  window.location.pathname
    .split("/")
  [window.location.pathname.split("/").length - 1].slice(1),
  resetPage = false,
  cancelReset = false
}: iPropstable) => {
  const controller = new AbortController();
  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    search: "",
  });
  useEffect(() => {
    if (resetPage) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: "",
      })
    }
  }, [resetPage])


  useEffect(() => {
    if (refetch) {
      settotalData(refetch);
    }
  }, [refetch]);
  const dt: any = useRef(null);
  const [loading, setLoading] = useState(false);

  const [visibleColumns, setVisibleColumns] = useState(columnData);



  const [totalRecord, setTotalRecords] = useState(0);
  const [totalData, settotalData]: any = useState(null);
  const [speedDial, setSpeedDial] = useState("bi bi-printer-fill");
  const role = localStorage.getItem("Roles");

  const [filterBy, setFilterBy]: any = useState({});
  const [sortBy, setSortBy]: any = useState({
    sortField: null,
    sortOrder: null,
  });
  const [IsSelf, setIsSelf]: any = useState(true);


  const { register }: any = useForm({ mode: "all" });
  console.log(isCalenderLeave, "check for calander");
  let networkTimeout: any = null;

  useEffect(() => {
    loadLazyData();
    return () => {
      controller.abort();
    }
  }, [lazyState, filterBy, sortBy]);

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      console.log(isCalenderLeave, "in load")
      PaginationService.getData(
        lazyState,
        apiname,
        doctorid,
        type,
        filterBy,
        sortBy,
        isCalenderLeave,
        employeeId,
        flagIsSelf ? IsSelf : "",
        controller.signal,
        hqId
      )
        .then((data) => {
          let temp = data.Data.map((e: any, index: any) => {
            return { ...e, srNo: ((data.PageNumber * data.PageSize) - data.PageSize) + (index + 1) }
          })

          console.log(temp, "this is temp Value")

          settotalData(temp);
          setTotalRecords(data.TotalDataCount);
          setLoading(false);
          if (typeof cancelReset != "boolean") {
            cancelReset(false);
          }
        })
        .catch((error) => {
          if (controller?.signal?.reason?.code == 20) {
            console.log('Request Canceled', error)
          }
          else {

            setLoading(false);
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setlazyState({
      ...lazyState,
      first: e.first,
      rows: e.rows,
      page: e.page + 1,
    });
  };

  const convert_filter_names = (matchMode: string) => {
    switch (matchMode) {
      case "equals":
        return "eq";
      case "notEquals":
        return "neq";
      case "contains":
        return "contains";
      case "notContains":
        return "nct";
      case "startsWith":
        return "startswith";
      case "endsWith":
        return "ewt";
      case "lt":
        return "lt";
      case "lte":
        return "lte";
      case "gt":
        return "gt";
      case "gte":
        return "gte";
      // case "dateIs":
      //   return "dateIs"
      // case "dateIsNot":
      //   return "dateIsNot"
      // case "dateBefore":
      //   return "dateBefore"
      // case "dateAfter":
      //   return "dateAfter"
      default:
        return matchMode;
    }
  };

  // Download Pdf Excel Csv Code
  const DataFormat = () => {
    const columnOfPdf = visibleColumns.filter(
      (data: any) => data.header !== "Action"
    );

    const pdfColumArr: any = [];
    const pdfDataArr: any = [];
    const pdfDisplayColumn: any = [];
    let checkColumns: any = [];
    let checkDate: any = [];

    columnOfPdf?.map((data: any) => {
      pdfDisplayColumn.push(data.header);
    });

    columnOfPdf?.map((data: any, i: any) => {

      if (data.dataType == "date" && data.mergeColums) {
        const DateColumn = data.mergeColumnsName[data.dataTypePosition];
        const otherColumn = data.mergeColumnsName.filter((d: any, i: any) => i !== data.dataTypePosition);
        pdfColumArr.push([DateColumn, ...otherColumn, "both"]);
        checkColumns = [...data.mergeColumnsName];
      }
      else if (data.dataType == "date") {

        pdfColumArr.push(["DateAndTime", data.field]);
        checkDate = [...checkDate, data.field];
      }
      else if (data.mergeColums && !(checkDate.includes(data.field))) {
        pdfColumArr.push([...data.mergeColumnsName]);
        checkColumns = [...data.mergeColumnsName];
      }
      else if (!(checkColumns.includes(data.field)) && data.field !== undefined && !(checkDate.includes(data.field))) {

        pdfColumArr.push(data.field);

      }

    });

    // console.log("All Fields", pdfColumArr)
    totalData?.map((mapTotalData: any) => {
      let tempArr: any = [];
      var tempKey: any = Object.keys(mapTotalData);

      pdfColumArr?.map((Column: any) => {

        if (Array.isArray(Column) && Column[0] != "DateAndTime") {
          let dummyData: any = [];
          console.log("colums len", Column)
          let MergeData = Column.map((mData: any, i: any) => {
            if (mData !== "both") {
              let finalField: any;
              let innerField: any;
              if (mData?.includes(".")) {
                finalField = mData.split(".")[0];
                innerField = mData.split(".")[1];
              } else {
                finalField = mData;
              }
              if (i === 0 && Column[Column.length - 1] == "both") {

                if (mapTotalData[finalField] != null && mapTotalData[finalField] != undefined && mapTotalData[finalField] != "" && finalField !== "both") {


                  dummyData.push(mapTotalData[finalField]?.split("T")[0]);

                }
                else {
                  if (finalField !== "both") {

                    dummyData.push("-");
                  }
                }
                // Column.pop();
              }
              else {
                if (typeof mapTotalData[finalField] == "object") {

                  if (mapTotalData[finalField]?.length > 0) {
                    dummyData.push(
                      mapTotalData[finalField]
                        ?.map((modelData: any) => typeof modelData?.[innerField] == "boolean" && modelData?.[innerField] == true ? "Yes" : typeof modelData?.[innerField] == "boolean" && modelData?.[innerField] == false ? "No" : modelData?.[innerField])
                        .join(",")
                    );
                  }
                  else {
                    dummyData.push("-");
                  }
                } else {
                  if (typeof mapTotalData[finalField] == "boolean" || (mapTotalData[finalField] != null && mapTotalData[finalField] != undefined && mapTotalData[finalField] != "")) {

                    dummyData.push(typeof mapTotalData?.[finalField] == "boolean" && mapTotalData?.[finalField] == true ? "Yes" : typeof mapTotalData?.[finalField] == "boolean" && mapTotalData?.[finalField] == false ? "No" : mapTotalData[finalField]);
                  }
                  else {

                    dummyData.push("-");
                  }
                }
              }
            }


          });

          tempArr.push(dummyData.join("  |  "));

        }
        else {
          if (Array.isArray(Column) && Column[0] == "DateAndTime") {
            // tempKey.map((TempKey: any) => {
            if (tempKey.includes(Column[1])) {
              if (typeof mapTotalData[Column[1]] == "boolean" || (mapTotalData[Column[1]] === null || mapTotalData[Column[1]] === undefined || mapTotalData[Column[1]] == "")) {
                tempArr.push("-");
              }
              else {

                tempArr.push(mapTotalData[Column[1]]?.split("T")[0]);
              }
            }
            // });
          }
          else {
            // tempKey.map((TempKey: any) => {
            let finalField;
            let innerField: any;
            if (Column?.includes(".")) {
              finalField = Column.split(".")[0];
              innerField = Column.split(".")[1];
              // console.log(TempKey, Column);
            } else {
              finalField = Column;
            }
            if (tempKey.includes(finalField)) {
              if (Array.isArray(mapTotalData[finalField])) {
                // mapTotalData[finalField] = mapTotalData[finalField]?.map((modelData: any) => modelData?.[innerField]).join(",")
                if (typeof mapTotalData[finalField] != "boolean" && (mapTotalData[finalField] === null || mapTotalData[finalField] === undefined || mapTotalData[finalField] == "")) {
                  tempArr.push("-");
                }
                else {
                  tempArr.push(
                    mapTotalData[finalField]
                      ?.map((modelData: any) => typeof modelData?.[innerField] == "boolean" && modelData?.[innerField] == true ? "Yes" : typeof modelData?.[innerField] == "boolean" && modelData?.[innerField] == false ? "No" : modelData?.[innerField])
                      .join(",")
                  );
                }

              }
              // if (mapTotalData[Column] == null) {
              //   tempArr.push("-");
              // }
              else {
                if (typeof mapTotalData[finalField] != "boolean" && (mapTotalData[finalField] === null || mapTotalData[finalField] === undefined || mapTotalData[finalField] == "")) {
                  tempArr.push("-");

                }
                else {
                  if (finalField == "StatusName") {
                    const StatusTemp = mapTotalData[finalField] == "Approve" ? "Approved" : mapTotalData[finalField] == "Reject" ? "Rejected" : mapTotalData[finalField];
                    tempArr.push(StatusTemp);
                  }
                  else {

                    tempArr.push(typeof mapTotalData?.[finalField] == "boolean" && mapTotalData?.[finalField] == true ? "Yes" : typeof mapTotalData?.[finalField] == "boolean" && mapTotalData?.[finalField] == false ? "No" : mapTotalData[finalField]);
                  }
                }
              }
            }
            // });
          }
        }
      });

      pdfDataArr.push(tempArr);
    });
    return {
      pdfDataArr: pdfDataArr,
      pdfDisplayColumn: pdfDisplayColumn
    }
  }

  const exportExcel = () => {
    console.log(visibleColumns, "visibleColumns")
    const columnOfPdf = visibleColumns.filter(
      (data: any) => data.header !== "Action"
    );

    const pdfColumArr: any = [];
    const pdfDataArr: any = [];
    let checkColumns: any = [];
    let checkDate: any = [];

    columnOfPdf.map((data: any) => {
      if (data.dataType == "date" && data.mergeColums) {
        const DateColumn = data.mergeColumnsName[data.dataTypePosition];
        const otherColumn = data.mergeColumnsName.filter((d: any, i: any) => i !== data.dataTypePosition);
        pdfColumArr.push([DateColumn, ...otherColumn, "both"]);
        checkColumns = [...data.mergeColumnsName];
      }
      else if (data.dataType == "date") {
        pdfColumArr.push(["DateAndTime", data.field]);
        checkDate = [...checkDate, data.field];
      }
      else if (data.mergeColums && !(checkDate.includes(data.field))) {
        pdfColumArr.push([...data.mergeColumnsName]);
        checkColumns = [...data.mergeColumnsName];
      }
      if (!(checkColumns.includes(data.field)) && data.field !== undefined && !(checkDate.includes(data.field))) {

        pdfColumArr.push(data.field);

      }

    });

    totalData.map((totalData1: any) => {

      console.log(totalData, "total data 5")
      let tempObj: any = {};
      var tempKey: any = Object.keys(totalData1);

      pdfColumArr.map((Column: any) => {
        if (Array.isArray(Column) && Column[0] != "DateAndTime") {


          let MergeData = Column.map((mData: any, i: any) => {

            if (mData !== "both") {

              let finalField: any;
              let innerField: any;
              if (mData?.includes(".")) {
                finalField = mData.split(".")[0];
                innerField = mData.split(".")[1];
              } else {
                finalField = mData;
              }
              console.log("TempKey", tempKey)
              if (i === 0 && Column[Column.length - 1] == "both") {
                // debugger
                console.log("data of column", totalData1[finalField], "date Time Issue")
                if (totalData1[finalField] !== null && totalData1[finalField] !== undefined && totalData1[finalField] !== "" && finalField !== "both") {



                  tempObj[finalField] = totalData1[finalField]?.split("T")[0]
                }
                else {
                  if (finalField !== "both") {

                    tempObj[finalField] = "-"
                  }
                }

              }
              else {
                if (Array.isArray(totalData1[finalField])) {
                  if (totalData1[finalField].length > 0 && totalData1[finalField] !== undefined && totalData1[finalField] !== null) {

                    tempObj[finalField] = totalData1[finalField]?.map((modelData: any) => typeof modelData?.[innerField] == "boolean" && modelData?.[innerField] == true ? "Yes" : typeof modelData?.[innerField] == "boolean" && modelData?.[innerField] == false ? "No" : modelData?.[innerField])
                      .join(",");
                  }
                  else {
                    tempObj[finalField] = "-"
                  }
                } else {
                  if (totalData1[finalField] !== undefined && totalData1[finalField] !== null) {
                    tempObj[finalField] = typeof totalData1?.[finalField] == "boolean" && totalData1?.[finalField] == true ? "Yes" : typeof totalData1?.[finalField] == "boolean" && totalData1?.[finalField] == false ? "No" : totalData1[finalField]
                  }
                  else {
                    tempObj[finalField] = "-"
                  }

                }
              }

            }
          });
        }
        else {
          if (Array.isArray(Column) && Column[0] == "DateAndTime") {


            if (tempKey.includes(Column[1])) {
              if (totalData1[Column[1]] !== undefined && totalData1[Column[1]] !== null) {
                tempObj[Column[1]] = totalData1[Column[1]]?.split("T")[0];
              }
              else {
                tempObj[Column[1]] = "-";
              }

            }
            // });

          }
          else {
            // tempKey.map((TempKey: any) => {
            let finalField;
            let innerField: any;
            if (Column?.includes(".")) {
              finalField = Column.split(".")[0];
              innerField = Column.split(".")[1];
              // console.log(TempKey, Column);
            } else {
              finalField = Column;
            }

            if (tempKey.includes(finalField)) {
              if (Array.isArray(totalData1[finalField])) {
                if (totalData1[finalField].length > 0 && totalData1[finalField] !== undefined && totalData1[finalField] !== null) {
                  tempObj[finalField] = totalData1[finalField]
                    ?.map((modelData: any) => typeof modelData?.[innerField] == "boolean" && modelData?.[innerField] == true ? "Yes" : typeof modelData?.[innerField] == "boolean" && modelData?.[innerField] == false ? "No" : modelData?.[innerField])
                    .join(",");
                }
                else {
                  tempObj[finalField] = "-"
                }

              } else {
                if (finalField == "StatusName") {
                  if (totalData1[finalField] !== undefined && totalData1[finalField] !== null) {
                    tempObj[finalField] = totalData1[finalField] == "Approve" ? "Approved" : totalData1[finalField] == "Reject" ? "Rejected" : totalData1[finalField];
                  }
                  else {
                    tempObj[finalField] = "-";
                  }
                }
                else {
                  if (totalData1[finalField] !== undefined && totalData1[finalField] !== null) {
                    tempObj[finalField] = typeof totalData1?.[finalField] == "boolean" && totalData1?.[finalField] == true ? "Yes" : typeof totalData1?.[finalField] == "boolean" && totalData1?.[finalField] == false ? "No" : totalData1[finalField];
                  }
                  else {
                    tempObj[finalField] = "-"
                  }
                }
              }
            }
            // });
          }

        }

      });
      pdfDataArr.push(tempObj);
    });
    // console.log("total data", pdfDataArr);

    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(pdfDataArr);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "Data");
    });
  };

  const saveAsExcelFile = (buffer: any, fileName: any) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const exportPdf = () => {
    var title = function (data: any) {
      // if (data.pageNumber === 1) {
      doc.setFontSize(15);
      doc.addFont(
        "Base64-encoded Data URI of the font",
        "Roboto Serif",
        "normal"
      );
      doc.addImage(
        logo,
        "JPEG",
        // data.settings.margin.left + 135,
        (data.cursor.x / 2) - 22,
        data.settings.margin.top - 25,
        6,
        6
      );
      doc.text(`${pdfTitle}`, data.settings.margin.left, 25);
      doc.text("Bios Lab Pvt. Ltd.",
        //  data.settings.margin.left + 142,
        (data.cursor.x / 2) - 15,
        10);
      doc.setFontSize(10);
      const d = new Date();
      let time: any = d.toISOString();
      d.toLocaleString();
      doc.text(
        `${d.toLocaleString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })} ${d.toLocaleTimeString()}`,
        // data.settings.margin.left + 287,
        data.cursor.x - data.settings.margin.left * 2.3,
        25
      );
      doc.text(
        "Address: 8-B, Internal Rd, Sarkhej, Ahmedabad, Gujarat 382210",
        // data.settings.margin.left + 110,
        (data.cursor.x / 2) - 50,
        15
      );
      // }
    };
    const { pdfDataArr, pdfDisplayColumn } = DataFormat();
    const Width = (35 * pdfDisplayColumn.length) > 150 ? 35 * pdfDisplayColumn.length : 150;
    const doc = new jsPDF("l", "mm", [Width, 350], true);

    autoTable(doc, {
      head: [pdfDisplayColumn],
      body: pdfDataArr,
      theme: "grid",
      margin: { top: 30 },
      didDrawPage: title,
    });

    doc.save("table.pdf");
  };

  const handlePrint = () => {
    var title = function (data: any) {
      // if (data.pageNumber === 1) {
      doc.setFontSize(15);
      doc.addFont(
        "Base64-encoded Data URI of the font",
        "Roboto Serif",
        "normal"
      );
      doc.addImage(
        logo,
        "JPEG",
        // (data.cursor.x / 2) - 22,
        data.settings.margin.left + 135,
        data.settings.margin.top - 25,
        6,
        6
      );
      doc.text(`${pdfTitle}`, data.settings.margin.left, 25);
      doc.text("Bios Lab Pvt. Ltd.",
        data.settings.margin.left + 142,
        // (data.cursor.x / 2) - 15, 
        10);
      doc.setFontSize(10);
      const d = new Date();
      let time: any = d.toISOString();
      d.toLocaleString();
      doc.text(
        `${d.toLocaleString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })} ${d.toLocaleTimeString()}`,
        // data.settings.margin.left + 235,
        data.cursor.x - data.settings.margin.left * 2.3,

        25
      );
      doc.text(
        "Address: 8-B, Internal Rd, Sarkhej, Ahmedabad, Gujarat 382210",
        data.settings.margin.left + 110,
        // (data.cursor.x / 2) - 50,

        15
      );
      // }
    };
    const { pdfDataArr, pdfDisplayColumn } = DataFormat();

    const doc = new jsPDF("l", "mm", "A4");
    // const doc = new jsPDF("l", "mm", [35 * pdfDisplayColumn.length, 350]);
    autoTable(doc, {
      head: [pdfDisplayColumn],
      body: pdfDataArr,
      theme: "grid",
      margin: { top: 30 },
      didDrawPage: title,
      // styles: { fontSize: 8, cellWidth: 14 },
      // headStyles: { fillColor: [45, 95, 43] }
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  // Download Pdf Excel Csv Code Complate
  const onFilter = (e: any) => {
    console.log(e, "this is filter data");
    let temp_array: any = {
      filters: [],
      logic: "and",
    };

    for (const [key, value] of Object.entries(e.filters)) {
      let final_value: any = value;
      if (final_value.constraints.length > 1) {
        let temp_constraints_array: any = [];

        for (let i = 0; i < final_value.constraints.length; i++) {
          if (final_value.constraints[i]?.value != null) {
            temp_constraints_array.push({
              operator: convert_filter_names(
                final_value.constraints[i]?.matchMode
              ),
              value: final_value.constraints[i]?.value,
              field: key,
            });
          }
        }

        if (temp_constraints_array.length > 0) {
          temp_array.filters.push({
            logic: final_value?.operator,
            filters: temp_constraints_array,
          });
        }
      } else {
        if (final_value.constraints[0]?.value != null) {
          temp_array.filters.push({
            field: key,
            operator: convert_filter_names(
              final_value?.constraints[0]?.matchMode
            ),
            value: final_value?.constraints[0]?.value,
          });
        }
      }
    }

    console.log("Filter = ", temp_array);
    setFilterBy(temp_array);
    setlazyState({
      ...lazyState,
      filters: e.filters,
    });
  };

  const OnSortChange = (e: any) => {
    setSortBy({
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };

  const handleSearch = (e: any) => {
    console.log(e.target.value.length, "Length");
    if (e.target.value.length >= 3) {
      setlazyState({
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    } else if (e.target.value.length === 0) {
      setlazyState({
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    }
  };

  const onColumnToggle = (event: any) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columnData.filter((col: any) =>
      selectedColumns.some((sCol: any) => sCol.field === col.field)
    );
    setVisibleColumns(orderedSelectedColumns);
  };

  useEffect(() => {
    if (refetchData) {

      loadLazyData();
      setrefetchData(false);
    }
  }, [refetchData]);

  const items = [
    {
      label: "Excel",
      icon: "pi pi-file-excel", 
      command: exportExcelFlag? exportExcelApi:exportExcel,
    },
    {
      label: "PDF",
      icon: "pi pi-file-pdf",
      command: exportPdf,
    },
    {
      label: "Print",
      icon: "pi pi-print",
      command: handlePrint,
    },
  ];

  console.log(lazyState, "lazystate");

  const header = enableDownload ? (
    <>
      <div className="d-flex justify-content-around row">
        {/* <div className=""> */}
        <div className="col-2 form-group mt-3">
          <input
            type="search"
            onChange={handleSearch}
            className="form-control"
            placeholder="Search"
          />
        </div>
        {/* </div> */}
        <div className="col-8">
          <Paginator
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalRecord}
            pageLinkSize={3}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={onPageChange}
          />
        </div>
        <div className="d-flex col-2">
          {/* <div className="col-6"> */}
          <SpeedDial
            model={items}
            direction="left"
            style={{
              zIndex: 10,
              marginTop: 0,
              // outline: "none"
            }}
            showIcon={speedDial}
            onShow={() => {
              setSpeedDial("bi bi-x-lg");
            }}
            onHide={() => {
              setSpeedDial("bi bi-printer-fill");
            }}
          />
          {/* </div> */}

          {/* <div>
            <span className="p-float-label col-6">
              <MultiSelect
                value={visibleColumns}
                onChange={onColumnToggle}
                // placeholder="Select Columns"
                options={columnData}
                optionLabel="header"
                maxSelectedLabels={0}
                selectedItemsLabel="{0} Visible Columns"
                style={{
                  borderRadius: "8px",
                }}
              />
            </span>
          </div> */}
        </div>
      </div>

      {/* {role !== "MR" && role !== "admin" && flagIsSelf && (
        <div className="row">
          <div className="col-md-12">
            <div className="custom-control custom-checkbox m-2">
              <input
                type="checkbox"
                className="custom-control-input"
                {...register("IsSelf")}
                id="IsSelf"
                onClick={() => {
                  if (IsSelf) {
                    setIsSelf(false);
                  } else {
                    setIsSelf(true);
                  }
                }}
                checked={IsSelf}
              />
              <label className="custom-control-label" htmlFor="IsSelf">
                Is Self
              </label>
            </div>
          </div>
        </div>
      )} */}
    </>
  ) : null;

  return (
    <>
      <div className="pd-20 card-box mb-30">
        <div className="row">
          <div className="col-md-12">
            <DataTable
              ref={dt}
              let-i="rowIndex"
              value={totalData}
              scrollable
              filterDisplay={"menu"}
              lazy
              dataKey="id"
              key={"id-1"}
              onFilter={onFilter}
              filters={filterJson}
              showGridlines={showGridlines}
              className="custom-datatable"
              stripedRows
              removableSort
              selectionMode={"checkbox"}
              onSort={OnSortChange}
              sortField={sortBy.sortField}
              sortOrder={sortBy.sortOrder}
              loading={loading}
              size="normal"
              rowHover
              editMode="row"
              emptyMessage="No data found."
              tableStyle={{ minWidth: "50rem" }}
              header={header}
            // filter
            >
              {check && (
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: "3rem" }}
                ></Column>
              )}



              {visibleColumns.map((e: any, index: any) => {
                return e.header !== "" ? (
                  <Column
                    field={e.field}
                    bodyStyle={{ textTransform: "capitalize" }}
                    bodyClassName={e.bodyClassName}
                    filterField={e.filterField}
                    style={{ minWidth: e.width ? e.width : "10vw", width: e.width ? e.width : "10vw" }}
                    key={`id-${index + 1}`}
                    dataType={e.dataType}
                    filter={e.filter}
                    headerTooltip={e.header}
                    headerTooltipOptions={{ position: "bottom" }}
                    frozen={e.frozen}
                    filterPlaceholder={e.filterPlaceholder}
                    filterElement={e.filterElement}
                    sortable={e.sortable}
                    header={e.header}
                    body={e.body}
                  />
                ) : (
                  ""
                );
              })}
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatatableCommon;
